@font-face {
  font-family: Gotham Medium;
  font-display: swap;
  src: url("Gotham-Medium.f64653e1.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gotham Light;
  font-display: swap;
  src: url("Gotham-Light.a3650dc5.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gotham Black;
  font-display: swap;
  src: url("Gotham-Black.68d261f5.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gotham Ultra;
  font-display: swap;
  src: url("Gotham-Ultra.88c7fbb5.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  color: #232323;
  background-color: #f8eee2;
  margin: 0;
  font-family: Gotham Medium, sans-serif;
  font-size: 20px;
  line-height: 30px;
}

@media only screen and (width <= 1160px) {
  body {
    font-size: 18px;
    line-height: 26px;
  }
}

@media only screen and (width <= 1023px) {
  body {
    font-size: 15px;
    line-height: 22px;
  }
}

a {
  color: #232323;
  text-decoration: none;
}

ul {
  margin: 0;
}

.button {
  color: #f8eee2;
  text-transform: uppercase;
  text-align: center;
  background-color: #232323;
  border: 3px solid #232323;
  padding: 20px;
  font-family: Gotham Black, sans-serif;
  font-size: 24px;
}

@media only screen and (width <= 1160px) {
  .button {
    font-size: 22px;
  }
}

@media only screen and (width <= 1024px) {
  .button {
    font-size: 19px;
  }
}

@media only screen and (width <= 1023px) {
  .button {
    font-size: 17px;
  }
}

.button:hover {
  color: #232323;
  background-color: #f8eee2;
}

.button:active {
  color: #f8eee2;
  background-color: #8699dd;
  border-color: #8699dd;
}

.link {
  color: #232323;
  z-index: 1;
  display: block;
  position: relative;
}

.link:before {
  content: "";
  z-index: -1;
  background: #8699dd;
  width: 106%;
  height: 3px;
  display: none;
  position: absolute;
  bottom: 4px;
  left: -3%;
}

.link:hover {
  color: #f8eee2;
}

.link:hover:before {
  display: block;
}

.link:active {
  color: #8699dd;
}

.link:active:before {
  display: none;
}

.link-black {
  color: #232323;
  z-index: 1;
  display: block;
  position: relative;
}

.link-black:before {
  content: "";
  z-index: -1;
  background: #8699dd;
  width: 106%;
  height: 3px;
  display: none;
  position: absolute;
  bottom: 8px;
  left: -3%;
}

.link-black:hover:before {
  display: block;
}

.link-black:active {
  color: #8699dd;
}

.link-black:active:before {
  display: none;
}

.ultra, .black {
  font-family: Gotham Black, sans-serif;
}

.upper {
  text-transform: uppercase;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.fs13 {
  font-size: 13px;
}

.fs18 {
  font-size: 18px;
}

@media only screen and (width <= 1160px) {
  .fs18 {
    font-size: 17px;
  }
}

@media only screen and (width <= 1080px) {
  .fs18 {
    font-size: 16px;
  }
}

@media only screen and (width <= 1024px) {
  .fs18 {
    font-size: 14px;
  }
}

.fs24 {
  font-size: 24px;
}

.mb35 {
  margin-bottom: 35px;
}

.red {
  color: #f4806c;
}

.light {
  color: #f8eee2;
}

.yellow {
  color: #ffbf57;
}

.blue {
  color: #8699dd;
}

.bg-red {
  background-color: #f4806c;
}

.bg-light {
  background-color: #f8eee2;
}

.bg-yellow {
  background-color: #ffbf57;
}

h3 {
  text-transform: uppercase;
  margin: 0;
  font-family: Gotham Black, sans-serif;
  font-size: 30px;
  line-height: 150%;
}

@media only screen and (width <= 767px) {
  h3 {
    font-size: 22px;
  }
}

.swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: #232323 !important;
  width: 24px !important;
  height: 24px !important;
  margin: 0 8px !important;
}

.swiper-pagination-bullet-active {
  background-color: #f4806c !important;
}

.swiper-button-prev:after, .swiper-button-next:after {
  content: unset !important;
}

.progressbar .card {
  text-align: center;
  width: 150px;
  height: 150px;
  transition: all .5s;
  position: relative;
}

.progressbar .percent {
  z-index: 1;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  position: relative;
}

.progressbar .percent h3 {
  margin-top: 10px;
  transition: all .5s;
}

.progressbar .percent .number {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.progressbar .percent .number h2 {
  margin: 0;
  transition: all .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.progressbar .percent svg {
  width: 140px;
  height: 160px;
  position: relative;
  overflow: visible;
  transform: rotate(-85deg);
}

.progressbar .percent svg circle {
  fill: none;
  stroke: #f8eee2;
  stroke-width: 16px;
  stroke-linecap: round;
  width: 100%;
  height: 100%;
  transform: translate(5px, 5px);
}

.progressbar .percent svg circle:nth-child(2) {
  stroke-dasharray: 440;
  stroke-dashoffset: 440px;
}

.progressbar .card-it:first-child svg circle:nth-child(2) {
  stroke-dashoffset: 211.2px;
  stroke: #232323;
}

.progressbar .card-product:first-child svg circle:nth-child(2) {
  stroke-dashoffset: 391.6px;
  stroke: #232323;
}

.progressbar .card-marketing:first-child svg circle:nth-child(2) {
  stroke-dashoffset: 308px;
  stroke: #232323;
}

.progressbar .card-rh:first-child svg circle:nth-child(2) {
  stroke-dashoffset: 426.8px;
  stroke: #232323;
}

.progressbar .card-finance:first-child svg circle:nth-child(2) {
  stroke-dashoffset: 413.6px;
  stroke: #232323;
}

@media only screen and (width <= 1024px) {
  .progressbar {
    transform: scale(.7);
  }

  .progressbar .card, .progressbar .percent, .progressbar svg {
    width: 140px;
  }
}

.app > header {
  width: 100%;
  display: inline-block;
}

.app > header .content {
  margin: 0 auto;
}

.app > section, .app > footer {
  width: 100%;
  display: inline-block;
}

.app .content {
  width: 1200px;
  margin: 50px auto 100px;
}

@media only screen and (width <= 1280px) {
  .app .content {
    width: 1100px;
  }
}

@media only screen and (width <= 1160px) {
  .app .content {
    width: 1040px;
  }
}

@media only screen and (width <= 1080px) {
  .app .content {
    width: 960px;
  }
}

@media only screen and (width <= 1024px) {
  .app .content {
    width: 860px;
  }
}

@media only screen and (width <= 940px) {
  .app .content {
    width: 769px;
  }
}

@media only screen and (width <= 1023px) {
  .app .content {
    box-sizing: border-box;
    width: 100%;
    margin: 50px auto;
    padding: 0 10px;
  }
}

.app .content__title {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 20px 0 30px;
  font-family: Gotham Black, sans-serif;
  font-size: 65px;
  line-height: 1.1;
}

@media only screen and (width <= 1160px) {
  .app .content__title {
    font-size: 70px;
  }
}

@media only screen and (width <= 1024px) {
  .app .content__title {
    font-size: 55px;
  }
}

@media only screen and (width <= 1023px) {
  .app .content__title {
    margin: 0 0 10px;
    font-size: 35px;
  }
}

@media only screen and (width <= 320px) {
  .app .content__title {
    margin: 0 0 10px;
    font-size: 32px;
  }
}

.app .content__title .small-title {
  font-size: 45px;
  display: block;
}

@media only screen and (width <= 1160px) {
  .app .content__title .small-title {
    font-size: 38px;
  }
}

@media only screen and (width <= 1024px) {
  .app .content__title .small-title {
    font-size: 32px;
  }
}

@media only screen and (width <= 1023px) {
  .app .content__title .small-title {
    font-size: 18px;
  }
}

@media only screen and (width <= 320px) {
  .app .content__title .small-title {
    font-size: 16px;
  }
}

.app__menu {
  background-color: #ffbf57;
  padding: 26px 0;
}

@media only screen and (width <= 1023px) {
  .app__menu {
    padding: 0;
  }
}

.app__menu__desktop {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media only screen and (width <= 1023px) {
  .app__menu__desktop {
    display: none;
  }
}

.app__menu__desktop__list {
  flex: 1;
  justify-content: space-around;
  margin-left: -80px;
  line-height: normal;
  display: flex;
}

.app__menu__desktop__separator {
  width: 190px;
  top: 5px;
  left: -59px;
}

@media only screen and (width <= 1080px) {
  .app__menu__desktop__separator {
    width: 140px;
    top: 6px;
  }
}

.app__menu__desktop__separator img {
  width: 100%;
  position: absolute;
  top: 34px;
}

@media only screen and (width <= 1080px) {
  .app__menu__desktop__separator img {
    top: 35px;
  }
}

@media only screen and (width <= 1024px) {
  .app__menu__desktop__separator img {
    top: 33px;
  }
}

@media only screen and (width <= 940px) {
  .app__menu__desktop__separator img {
    top: 42px;
  }
}

.app__menu__desktop__logo {
  background-color: #f8eee2;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  display: flex;
  position: absolute;
  top: -16px;
  left: 3%;
  transform: translateX(-50%);
}

@media only screen and (width <= 1200px) {
  .app__menu__desktop__logo {
    left: 3.5%;
  }
}

@media only screen and (width <= 1080px) {
  .app__menu__desktop__logo {
    width: 70px;
    height: 70px;
    left: 1.5%;
  }
}

.app__menu__desktop__logo img {
  width: 90%;
  transform: rotate(-21deg);
}

.app__menu__mobile {
  display: none;
}

@media only screen and (width <= 1023px) {
  .app__menu__mobile {
    display: block;
  }
}

.app__menu__mobile__logo {
  background-color: #f8eee2;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  display: flex;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.app__menu__mobile__logo img {
  width: 80%;
  transform: rotate(-21deg);
}

.app__menu__mobile__separator {
  left: 50%;
  transform: translateX(-50%);
}

.app__menu__mobile__separator img {
  width: 192px;
}

.app__menu__mobile__list {
  display: none;
}

.app__menu__mobile__toggle {
  text-align: right;
  padding: 16px 0;
}

.app__menu__mobile__toggle .toggle_menu {
  background: url("burger_menu_closed.54ace544.svg");
  border: none;
  width: 20px;
  height: 18px;
}

.app__menu__mobile.opened .app__menu__mobile__list {
  flex-direction: column;
  align-items: center;
  margin: 30px 0 10px;
  display: flex;
}

.app__menu__mobile.opened .app__menu__mobile__list__item {
  padding: 5px 0;
}

.app__menu__mobile.opened .app__menu__mobile__separator {
  top: calc(100% + 10px);
}

.app__menu__mobile.opened .app__menu__mobile__toggle .toggle_menu {
  background-image: url("burger_menu_opened.2024d32c.svg");
  width: 20px;
  height: 19px;
}

.app__intro {
  background-image: url("pattern_neutral.1e9bcfe1.png");
  background-position: top;
  background-size: 40%;
}

@media only screen and (width <= 1023px) {
  .app__intro {
    background-size: 100%;
    padding-top: 40px;
  }
}

.app__intro__mobile-badge {
  text-align: right;
  display: none;
}

@media only screen and (width <= 1023px) {
  .app__intro__mobile-badge {
    display: block;
  }
}

.app__intro__mobile-badge img {
  width: 114px;
  margin-right: 35px;
}

.app__intro .content {
  margin: 100px auto 50px;
}

@media only screen and (width <= 1023px) {
  .app__intro .content {
    margin: 50px auto;
    padding: 0 25px;
  }
}

.app__intro .content__title {
  z-index: 1;
  margin: 20px 0 55px;
  font-size: 65px;
  position: relative;
}

@media only screen and (width <= 1160px) {
  .app__intro .content__title {
    margin: 20px 0 75px;
    font-size: 55px;
  }
}

@media only screen and (width <= 1024px) {
  .app__intro .content__title {
    margin: 20px 0 75px;
    font-size: 46px;
  }
}

@media only screen and (width <= 1023px) {
  .app__intro .content__title {
    font-size: 37px;
  }
}

@media only screen and (width <= 320px) {
  .app__intro .content__title {
    font-size: 31px;
  }
}

.app__intro .content .flex {
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -210px;
}

@media only screen and (width <= 1023px) {
  .app__intro .content .flex {
    margin-top: 0;
  }
}

.app__intro .content .flex .button {
  margin-bottom: 45px;
  padding: 20px 100px 20px 95px;
  position: relative;
}

@media only screen and (width <= 1280px) {
  .app__intro .content .flex .button {
    padding: 20px 50px 20px 45px;
  }
}

@media only screen and (width <= 1023px) {
  .app__intro .content .flex .button {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 24px 20px 12px;
  }
}

.app__intro .content .flex .button img {
  width: 12px;
  margin-left: 8px;
}

.app__intro .content .flex .button:before {
  content: "";
  background-image: url("right_arrow.b3e4b7e0.svg");
  width: 13px;
  height: 18px;
  display: block;
  position: absolute;
  top: 24px;
  right: 75px;
}

@media only screen and (width <= 1280px) {
  .app__intro .content .flex .button:before {
    right: 30px;
  }
}

@media only screen and (width <= 1023px) {
  .app__intro .content .flex .button:before {
    top: 22px;
    right: 44px;
  }
}

@media only screen and (width <= 375px) {
  .app__intro .content .flex .button:before {
    top: 22px;
    right: 28px;
  }
}

@media only screen and (width <= 320px) {
  .app__intro .content .flex .button:before {
    top: 22px;
    right: 20px;
  }
}

.app__intro .content .flex .button:hover:before {
  background-image: url("right_arrow_black.32c43a8d.svg");
}

.app__intro .content .flex .button:active:before {
  background-image: url("right_arrow.b3e4b7e0.svg");
}

.app__intro .content .flex > div {
  position: relative;
}

.app__intro .content .flex > div .photo {
  width: 588px;
}

@media only screen and (width <= 1360px) {
  .app__intro .content .flex > div .photo {
    margin-right: 0;
  }
}

@media only screen and (width <= 1024px) {
  .app__intro .content .flex > div .photo {
    width: 400px;
  }
}

.app__intro .content .flex > div .badge {
  width: 132px;
  position: absolute;
  top: -100px;
  right: -10px;
}

@media only screen and (width <= 1360px) {
  .app__intro .content .flex > div .badge {
    right: 20px;
  }
}

@media only screen and (width <= 1160px) {
  .app__intro .content .flex > div .badge {
    top: -80px;
  }
}

@media only screen and (width <= 1024px) {
  .app__intro .content .flex > div .badge {
    top: -100px;
    right: 0;
  }
}

@media only screen and (width <= 1023px) {
  .app__intro .content .flex > div .badge {
    display: none;
  }
}

.app__mission {
  background-color: #f4806c;
  background-image: url("pattern_red.b0a15740.png");
  background-position: top;
  background-size: 40%;
}

.app__mission .content .flex {
  align-items: center;
}

@media only screen and (width <= 1023px) {
  .app__mission .content .flex {
    flex-direction: column;
  }
}

.app__mission .content__title img {
  width: 84px;
  margin-bottom: -12px;
}

@media only screen and (width <= 1023px) {
  .app__mission .content__title img {
    width: 60px;
    margin: 20px;
    display: block;
  }
}

.app__mission .content__description {
  padding-right: 185px;
}

@media only screen and (width <= 1360px) {
  .app__mission .content__description {
    padding-right: 145px;
  }
}

@media only screen and (width <= 1280px) {
  .app__mission .content__description {
    padding-left: 15px;
    padding-right: 35px;
  }
}

@media only screen and (width <= 1023px) {
  .app__mission .content__description {
    margin-bottom: 15px;
    padding-left: 0;
    padding-right: 35px;
  }
}

.app__mission .content__description-bottom {
  padding-left: 110px;
}

.app__mission .content__description-bottom p {
  margin-top: 0;
}

@media only screen and (width <= 1360px) {
  .app__mission .content__description-bottom {
    padding-left: 115px;
  }
}

@media only screen and (width <= 1280px) {
  .app__mission .content__description-bottom {
    padding-left: 35px;
  }
}

@media only screen and (width <= 1023px) {
  .app__mission .content__description-bottom {
    margin-bottom: 15px;
    padding-left: 0;
  }
}

.app__mission .content .video-container {
  width: 100%;
}

.app__mission .content .video-container iframe {
  box-sizing: border-box;
  border: 20px solid #f8eee2;
  margin-right: -50px;
  box-shadow: 6px 6px #0000001a;
}

@media only screen and (width <= 1360px) {
  .app__mission .content .video-container iframe {
    margin-right: -10px;
  }
}

@media only screen and (width <= 1024px) {
  .app__mission .content .video-container iframe {
    width: 480px;
  }
}

@media only screen and (width <= 940px) {
  .app__mission .content .video-container iframe {
    width: 440px;
    margin-right: 10px;
  }
}

@media only screen and (width <= 1023px) {
  .app__mission .content .video-container iframe {
    width: 100%;
    margin-right: -10px;
  }
}

@media only screen and (width <= 640px) {
  .app__mission .content .video-container iframe {
    height: auto;
  }
}

.app__products .content > .flex {
  justify-content: space-between;
}

.app__products .content > .flex img {
  width: 134px;
}

@media only screen and (width <= 1023px) {
  .app__products .content > .flex img {
    width: 61px;
    height: 65px;
    margin: 20px auto;
    display: block;
  }
}

@media only screen and (width <= 1024px) {
  .app__products .content__description .flex {
    flex-direction: column;
  }

  .app__products .content__description .flex.icons {
    flex-direction: row;
  }
}

.app__products .content__description .flex .product {
  box-sizing: border-box;
  text-align: center;
  flex-grow: 1;
  padding: 20px 40px;
  font-size: 16px;
  line-height: 24px;
  box-shadow: 6px 6px #0000001a;
}

@media only screen and (width <= 1250px) {
  .app__products .content__description .flex .product {
    padding: 20px 22px;
  }

  .app__products .content__description .flex .product.nsf > .icons {
    flex-wrap: wrap;
  }
}

@media only screen and (width >= 1024px) {
  .app__products .content__description .flex .product {
    max-width: 340px;
  }
}

@media only screen and (width <= 1024px) {
  .app__products .content__description .flex .product {
    width: 60%;
    margin: 20px auto;
  }
}

@media only screen and (width <= 1023px) {
  .app__products .content__description .flex .product {
    width: 100%;
    height: inherit;
    margin: 15px 0;
    padding: 20px 16px;
    font-size: 15px;
    line-height: 22px;
  }

  .app__products .content__description .flex .product p {
    margin: 10px 0;
  }
}

.app__products .content__description .flex .product .icons {
  justify-content: space-between;
}

.app__products .content__description .flex .wyylde {
  color: #fff;
  background-color: #9c0c00;
  background-size: cover;
}

.app__products .content__description .flex .wyylde .logo {
  width: 245px;
  margin: 20px 0 0;
}

.app__products .content__description .flex .wyylde .highlight {
  font-family: Gotham Black, sans-serif;
}

.app__products .content__description .flex .wyylde .highlight span {
  color: #ff4c42;
}

.app__products .content__description .flex .nsf {
  background: url("nsf_background.cf67ee3c.png") 0 0 / cover;
  position: relative;
}

.app__products .content__description .flex .nsf .reflect {
  background: #fff3;
  width: 120%;
  height: 130px;
  position: absolute;
  top: -20px;
  transform: rotate(-5deg);
}

@media only screen and (width <= 1023px) {
  .app__products .content__description .flex .nsf .reflect {
    height: 120px;
  }
}

.app__products .content__description .flex .nsf > a {
  z-index: 1;
}

.app__products .content__description .flex .nsf > a .logo-container {
  background: #000;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 136px;
  margin: 0 auto;
  display: flex;
}

.app__products .content__description .flex .nsf > a .logo-container .logo {
  width: 60%;
}

.app__products .content__description .flex .nsf .highlight {
  color: #fff;
  font-family: Gotham Black, sans-serif;
}

.app__tools {
  background-color: #ffbf57;
}

.app__tools .content > .flex {
  justify-content: space-between;
}

.app__tools .content > .flex img {
  width: 73px;
  height: 107px;
  margin-top: 15px;
}

@media only screen and (width <= 1023px) {
  .app__tools .content > .flex img {
    margin-top: -30px;
  }
}

.app__tools .content__description-tools {
  grid-template-columns: repeat(6, 1fr);
  gap: 60px;
  margin-top: 20px;
}

@media only screen and (width <= 1160px) {
  .app__tools .content__description-tools {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (width <= 1023px) {
  .app__tools .content__description-tools {
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
  }
}

.app__tools .content__description-tools .tool {
  box-sizing: border-box;
  background: #f8eee2;
  width: 100%;
  min-width: 123px;
  max-width: 150px;
  min-height: 112px;
  max-height: 115px;
  margin: auto;
  padding: 25px 0;
  display: flex;
  position: relative;
}

.app__tools .content__description-tools .tool p {
  text-transform: uppercase;
  margin: 0;
  font-family: Gotham Black, sans-serif;
  font-size: 34px;
  display: none;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.app__tools .content__description-tools .tool img {
  margin: auto;
  display: block;
}

.app__tools .content__description-tools .tool img.active {
  display: none;
}

.app__tools .content__description-tools .tool:hover {
  box-shadow: 6px 6px #0000001a;
}

.app__tools .content__description-tools .tool:hover img {
  display: none;
}

.app__tools .content__description-tools .tool:hover img.active, .app__tools .content__description-tools .tool:hover p {
  display: block;
}

.app__jobs {
  background-color: #f8eee2;
  background-position: top;
  background-size: 40%;
}

@media only screen and (width <= 1023px) {
  .app__jobs .content {
    margin: 50px 0 20px;
  }
}

.app__jobs .content > .flex {
  justify-content: space-between;
}

.app__jobs .content > .flex img {
  width: 183px;
  height: 136px;
}

@media only screen and (width <= 1023px) {
  .app__jobs .content > .flex img {
    width: 102px;
    height: 76px;
  }
}

.app__jobs .content__description .offers {
  text-align: center;
}

@media only screen and (width <= 1023px) {
  .app__jobs .content__description .offers {
    margin-top: inherit;
  }

  .app__jobs .content__description .offers .button {
    display: block;
  }
}

.app__contact .content__description > p {
  margin-bottom: 60px;
}

@media only screen and (width <= 1023px) {
  .app__contact .content__description > p {
    margin-bottom: 40px;
  }
}

@media only screen and (width <= 640px) {
  .app__contact .content__description .flex {
    flex-direction: column;
  }
}

.app__contact .content__description .flex > img {
  width: 210px;
  height: 132px;
  margin: 40px 132px;
}

@media only screen and (width <= 940px) {
  .app__contact .content__description .flex > img {
    margin: 40px 82px;
  }
}

@media only screen and (width <= 1023px) {
  .app__contact .content__description .flex > img {
    width: 120px;
    height: 75px;
    margin: 40px 100px;
  }
}

@media only screen and (width <= 640px) {
  .app__contact .content__description .flex > img {
    width: 120px;
    height: 75px;
    margin: 0 auto 60px;
  }
}

.app__contact .content__description .flex .contact > div {
  margin-bottom: 20px;
  display: flex;
}

.app__contact .content__description .flex .contact > div .icon {
  text-align: center;
  width: 50px;
}

.app__contact .content__description .flex .contact > div p {
  margin: 0;
}

.app__footer {
  background-color: #232323;
}

.app__footer .content {
  margin: 40px auto 20px;
}

.app__footer .content a {
  text-decoration: underline;
  color: #fff !important;
}

.app__footer .content #societe {
  text-align: center;
  flex-flow: column;
  font-size: 10px;
  display: flex;
}

.app__footer .content .flex {
  justify-content: center;
}

@media only screen and (width <= 1023px) {
  .app__footer .content .flex {
    flex-direction: column;
  }
}

.app__footer .content .flex.apps a {
  text-align: center;
  width: 145px;
  margin: 0 50px;
}

@media only screen and (width <= 1023px) {
  .app__footer .content .flex.apps a {
    margin: 10px auto;
  }
}

.app__footer .content .flex.apps a img.wyylde {
  width: 145px;
}

.app__footer .content .flex.apps a img.kinky {
  width: 110px;
}

.app__footer .content .flex.apps a img.nsf {
  width: 67px;
}

.app__footer .content .flex.links {
  margin: 30px 0;
  font-size: 16px;
}

@media only screen and (width <= 1023px) {
  .app__footer .content .flex.links {
    margin: 10px auto;
  }
}

.app__footer .content .flex.links a {
  margin: 0 80px;
}

@media only screen and (width <= 1023px) {
  .app__footer .content .flex.links a {
    margin: 10px auto;
  }
}

.app__footer .content p.small {
  text-align: center;
  margin-top: 80px;
  font-size: 12px;
}
/*# sourceMappingURL=index.065d9269.css.map */
