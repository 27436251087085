
// COLORS

$light: #f8eee2;
$dark: #232323;
$yellow: #ffbf57;
$red: #f4806c;
$blue: #8699dd;

// FONTS

@font-face {
  font-family: "Gotham Medium";
  font-display: swap;
  src: url("./assets/fonts/Gotham-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Light";
  font-display: swap;
  src: url("./assets/fonts/Gotham-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Black";
  font-display: swap;
  src: url("./assets/fonts/Gotham-Black.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Ultra";
  font-display: swap;
  src: url("./assets/fonts/Gotham-Ultra.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

// STYLES

// Common

body {
  background-color: $light;
  font-family: "Gotham Medium", sans-serif;
  color: $dark;
  margin: 0;
  font-size: 20px;
  line-height: 30px;

  @media only screen and (max-width: 1160px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 15px;
    line-height: 22px;
  }
}

a {
  text-decoration: none;
  color: $dark;
}

ul {
  margin: 0;
}

.button {
  background-color: $dark;
  color: $light;
  padding: 20px;
  text-transform: uppercase;
  font-family: "Gotham Black", sans-serif;
  font-size: 24px;
  text-align: center;
  border: 3px solid $dark;

  @media only screen and (max-width: 1160px) {
    font-size: 22px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 19px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 17px;
  }

  &:hover {
    background-color: $light;
    color: $dark;
  }

  &:active {
    background-color: $blue;
    color: $light;
    border-color: $blue;
  }
}

.link {
  color: $dark;
  display: block;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    width: 106%;
    height: 3px;
    background: #8699dd;
    display: none;
    bottom: 4px;
    z-index: -1;
    left: -3%;
  }

  &:hover {
    color: $light;

    &::before {
      display: block;
    }
  }

  &:active {
    color: $blue;

    &::before {
      display: none;
    }
  }
}

.link-black {
    color: $dark;
    display: block;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      width: 106%;
      height: 3px;
      background: #8699dd;
      display: none;
      bottom: 8px;
      z-index: -1;
      left: -3%;
    }

    &:hover {
      &::before {
        display: block;
      }
    }

    &:active {
      color: $blue;

      &::before {
        display: none;
      }
    }
  }

.ultra {
  font-family: "Gotham Black", sans-serif;
}

.black {
  font-family: "Gotham Black", sans-serif;
}

.upper {
  text-transform: uppercase;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.fs13 {
  font-size: 13px;
}

.fs18 {
  font-size: 18px;

  @media only screen and (max-width: 1160px) {
    font-size: 17px;
  }

  @media only screen and (max-width: 1080px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 14px;
  }
}

.fs24 {
  font-size: 24px;
}

.mb35 {
  margin-bottom: 35px;
}

.red {
  color: $red;
}

.light {
  color: $light;
}

.yellow {
  color: $yellow;
}

.blue {
  color: $blue;
}

.bg-red {
  background-color: $red;
}

.bg-light {
  background-color: $light;
}

.bg-yellow {
  background-color: $yellow;
}

h3 {
    font-family: "Gotham Black", sans-serif;
    font-size: 30px;
    text-transform: uppercase;
    line-height: 150%;
    margin: 0;

    @media only screen and (max-width: 767px) {
        font-size: 22px;
      }
}

// Slider
.swiper-pagination-bullet {
    background-color: $dark !important;
    width: 24px !important;
    height: 24px !important;
    margin: 0 8px !important;
    opacity: 1 !important;

    &-active {
        background-color: $red !important;
    }
}

.swiper-button-prev::after, .swiper-button-next::after {
  content: unset !important;
}

// Progress bar
.progressbar {
    .card {
      position: relative;
      width: 150px;
      height: 150px;
      text-align: center;
      transition: 0.5s ease;
    }

    .percent {
      position: relative;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      z-index: 1;

      h3 {
        margin-top: 10px;
        transition: 0.5s ease;
      }

      .number {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;

        h2 {
          transition: 0.5s ease;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          margin: 0;
        }
      }

      svg {
        position: relative;
        width: 140px;
        height: 160px;
        transform: rotate(-85deg);
        overflow: visible;

        circle {
          width: 100%;
          height: 100%;
          fill: none;
          stroke: $light;
          stroke-width: 16;
          stroke-linecap: round;
          transform: translate(5px, 5px);

          &:nth-child(2) {
            stroke-dasharray: 440;
            stroke-dashoffset: 440;
          }
        }
      }
    }

    .card {
        &-it {
            &:nth-child(1) {
              svg {
                circle {
                  &:nth-child(2) {
                    stroke-dashoffset: calc(440px - (440px * 52) / 100);
                    stroke: $dark;
                  }
                }
              }
            }
        }

        &-product {
            &:nth-child(1) {
              svg {
                circle {
                  &:nth-child(2) {
                    stroke-dashoffset: calc(440px - (440px * 11) / 100);
                    stroke: $dark;
                  }
                }
              }
            }
        }

        &-marketing {
            &:nth-child(1) {
              svg {
                circle {
                  &:nth-child(2) {
                    stroke-dashoffset: calc(440px - (440px * 30) / 100);
                    stroke: $dark;
                  }
                }
              }
            }
        }

        &-rh {
            &:nth-child(1) {
              svg {
                circle {
                  &:nth-child(2) {
                    stroke-dashoffset: calc(440px - (440px * 3) / 100);
                    stroke: $dark;
                  }
                }
              }
            }
        }

        &-finance {
            &:nth-child(1) {
              svg {
                circle {
                  &:nth-child(2) {
                    stroke-dashoffset: calc(440px - (440px * 6) / 100);
                    stroke: $dark;
                  }
                }
              }
            }
        }
    }

    @media only screen and (max-width: 1024px) {
        transform: scale(0.7);
        .card,
        .percent,
        svg {
            width: 140px;
        }
    }
  }

// Design

.app {
  > header {
    display: inline-block;
    width: 100%;

    .content {
      margin: 0 auto;
    }
  }

  > section,
  > footer {
    display: inline-block;
    width: 100%;
  }

  .content {
    width: 1200px;
    margin: 50px auto 100px;

    @media only screen and (max-width: 1280px) {
      width: 1100px;
    }

    @media only screen and (max-width: 1160px) {
      width: 1040px;
    }

    @media only screen and (max-width: 1080px) {
      width: 960px;
    }

    @media only screen and (max-width: 1024px) {
      width: 860px;
    }

    @media only screen and (max-width: 940px) {
      width: 769px;
    }

    @media only screen and (max-width: 1023px) {
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      margin: 50px auto;
    }

    &__title {
      font-size: 65px;
      text-transform: uppercase;
      font-family: "Gotham Black", sans-serif;
      line-height: 1.1;
      letter-spacing: 2px;
      margin: 20px 0 30px;

      @media only screen and (max-width: 1160px) {
        font-size: 70px;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 55px;
      }

      @media only screen and (max-width: 1023px) {
        font-size: 35px;
        margin: 0 0 10px;
      }

      @media only screen and (max-width: 320px) {
        font-size: 32px;
        margin: 0 0 10px;
      }

      .small-title {
        font-size: 45px;
        display: block;

        @media only screen and (max-width: 1160px) {
          font-size: 38px;
        }

        @media only screen and (max-width: 1024px) {
          font-size: 32px;
        }

        @media only screen and (max-width: 1023px) {
          font-size: 18px;
        }

        @media only screen and (max-width: 320px) {
          font-size: 16px;
        }
      }
    }
  }

  &__menu {
    background-color: $yellow;
    padding: 26px 0;

    @media only screen and (max-width: 1023px) {
      padding: 0;
    }

    &__desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 1023px) {
        display: none;
      }

      &__list {
        flex: 1;
        display: flex;
        justify-content: space-around;
        line-height: normal;
        margin-left: -80px;
      }

      &__separator {
        width: 190px;
        left: -59px;
        top: 5px;

        @media only screen and (max-width: 1080px) {
          width: 140px;
          top: 6px;
        }

        img {
          width: 100%;
          position: absolute;
          top: 34px;

          @media only screen and (max-width: 1080px) {
            top: 35px;
          }

          @media only screen and (max-width: 1024px) {
            top: 33px;
          }

          @media only screen and (max-width: 940px) {
            top: 42px;
          }
        }
      }

      &__logo {
        width: 80px;
        height: 80px;
        background-color: $light;
        position: absolute;
        left: 3%;
        transform: translateX(-50%);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        top: -16px;

        @media only screen and (max-width: 1200px) {
            left: 3.5%;
          }

        @media only screen and (max-width: 1080px) {
          width: 70px;
          height: 70px;
          left: 1.5%;
        }

        img {
          width: 90%;
          transform: rotate(-21deg);
        }
    }
}

&__mobile {
    display: none;

    @media only screen and (max-width: 1023px) {
        display: block;
    }

    &__logo {
        top: 10px;
        width: 60px;
        height: 60px;
        background-color: $light;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 80%;
            transform: rotate(-21deg);
        }
      }

      &__separator {
        left: 50%;
        transform: translateX(-50%);

        img {
          width: 192px;
        }
      }

      &__list {
        display: none;
      }

      &__toggle {
        padding: 16px 0;
        text-align: right;

        .toggle_menu {
          background: transparent;
          border: none;
          background-image: url("./assets/icons/burger_menu_closed.svg");
          width: 20px;
          height: 18px;
        }
      }

      &.opened {
        .app__menu__mobile__list {
          flex-direction: column;
          align-items: center;
          display: flex;
          margin: 30px 0 10px;

          &__item {
            padding: 5px 0;
          }
        }

        .app__menu__mobile__separator {
          top: calc(100% + 10px);
        }

        .app__menu__mobile__toggle {
          .toggle_menu {
            background-image: url("./assets/icons/burger_menu_opened.svg");
            width: 20px;
            height: 19px;
          }
        }
      }
    }
  }

  &__intro {
    background-image: url("./assets/backgrounds/pattern_neutral.png");
    background-position: center top;
    background-size: 40%;

    @media only screen and (max-width: 1023px) {
      padding-top: 40px;
      background-size: 100%;
    }

    &__mobile-badge {
      display: none;
      text-align: right;

      @media only screen and (max-width: 1023px) {
        display: block;
      }

      img {
        width: 114px;
        margin-right: 35px;
      }
    }

    .content {
      margin: 100px auto 50px;

      @media only screen and (max-width: 1023px) {
        margin: 50px auto;
        padding: 0 25px;
      }

      &__title {
        font-size: 65px;
        position: relative;
        z-index: 1;
        margin: 20px 0 55px;

        @media only screen and (max-width: 1160px) {
          font-size: 55px;
          margin: 20px 0 75px;
        }

        @media only screen and (max-width: 1024px) {
          font-size: 46px;
          margin: 20px 0 75px;
        }

        @media only screen and (max-width: 1023px) {
          font-size: 37px;
        }

        @media only screen and (max-width: 320px) {
          font-size: 31px;
        }
      }

      .flex {
        justify-content: space-between;
        align-items: flex-end;
        margin-top: -210px;

        @media only screen and (max-width: 1023px) {
          margin-top: 0;
        }

        .button {
          margin-bottom: 45px;
          padding: 20px 100px 20px 95px;
          position: relative;

          @media only screen and (max-width: 1280px) {
            padding: 20px 50px 20px 45px;
          }

          @media only screen and (max-width: 1023px) {
            padding: 20px 24px 20px 12px;
            width: 100%;
            margin-bottom: 20px;
          }

          img {
            width: 12px;
            margin-left: 8px;
          }

          &::before {
            content: "";
            background-image: url("./assets/icons/right_arrow.svg");
            width: 13px;
            height: 18px;
            display: block;
            right: 75px;
            top: 24px;
            position: absolute;

            @media only screen and (max-width: 1280px) {
              right: 30px;
            }

            @media only screen and (max-width: 1023px) {
              right: 44px;
              top: 22px;
            }

            @media only screen and (max-width: 375px) {
              right: 28px;
              top: 22px;
            }

            @media only screen and (max-width: 320px) {
              right: 20px;
              top: 22px;
            }
          }

          &:hover {
            &::before {
              background-image: url("./assets/icons/right_arrow_black.svg");
            }
          }

          &:active {
            &::before {
              background-image: url("./assets/icons/right_arrow.svg");
            }
          }
        }

        > div {
          position: relative;

          .photo {
            width: 588px;

            @media only screen and (max-width: 1360px) {
              margin-right: 0;
            }

            @media only screen and (max-width: 1024px) {
              width: 400px;
            }
          }

          .badge {
            width: 132px;
            position: absolute;
            right: -10px;
            top: -100px;

            @media only screen and (max-width: 1360px) {
              right: 20px;
            }

            @media only screen and (max-width: 1160px) {
              top: -80px;
            }

            @media only screen and (max-width: 1024px) {
              top: -100px;
              right: 0;
            }

            @media only screen and (max-width: 1023px) {
              display: none;
            }
          }
        }
      }
    }
  }

  &__mission {
    background-color: $red;
    background-image: url("./assets/backgrounds/pattern_red.png");
    background-position: center top;
    background-size: 40%;

    .content {
      .flex {
        align-items: center;

        @media only screen and (max-width: 1023px) {
          flex-direction: column;
        }
      }

      &__title {
        img {
          width: 84px;
          margin-bottom: -12px;

          @media only screen and (max-width: 1023px) {
            display: block;
            margin: 20px;
            width: 60px;
          }
        }
      }

      &__description {
        padding-right: 185px;

        @media only screen and (max-width: 1360px) {
          padding-right: 145px;
        }

        @media only screen and (max-width: 1280px) {
          padding-right: 65px;
        }

        @media only screen and (max-width: 1280px) {
          padding-right: 35px;
          padding-left: 15px;
        }

        @media only screen and (max-width: 1023px) {
          padding-right: 35px;
          padding-left: 0;
          margin-bottom: 15px;
        }
      }

      &__description-bottom {
        padding-left: 110px;

        p {
            margin-top: 0;
        }

        @media only screen and (max-width: 1360px) {
          padding-left: 115px;
        }

        @media only screen and (max-width: 1280px) {
          padding-left: 35px;
        }

        @media only screen and (max-width: 1023px) {
          padding-left: 35px;
          padding-left: 0;
          margin-bottom: 15px;
        }
      }

      .video-container {
        width: 100%;

        iframe {
          border: 20px solid $light;
          box-sizing: border-box;
          margin-right: -50px;
          box-shadow: 6px 6px 0 rgb(0 0 0 / 10%);

          @media only screen and (max-width: 1360px) {
            margin-right: -10px;
          }

          @media only screen and (max-width: 1024px) {
            width: 480px;
          }

          @media only screen and (max-width: 940px) {
            width: 440px;
            margin-right: 10px;
          }

          @media only screen and (max-width: 1023px) {
            width: 100%;
            margin-right: -10px;
          }

          @media only screen and (max-width: 640px) {
            height: auto;
          }
        }
      }
    }
  }

  &__products {
    .content {
      > .flex {
        justify-content: space-between;

        img {
          width: 134px;

          @media only screen and (max-width: 1023px) {
            display: block;
            margin: 20px auto;
            width: 61px;
            height: 65px;
          }
        }
      }

      &__description {
        .flex {

          @media only screen and (max-width: 1024px) {
            flex-direction: column;

            &.icons {
              flex-direction: row;
            }
          }

          .product {
            flex-grow: 1;
            padding: 20px 40px;
            box-sizing: border-box;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            box-shadow: 6px 6px 0 rgb(0 0 0 / 10%);

            @media only screen and (max-width: 1250px) {
              padding: 20px 22px;

              &.nsf > .icons {
                flex-wrap: wrap;
              }
            }


            @media only screen and (min-width: 1024px) {
              max-width: 340px;
            }

            @media only screen and (max-width: 1024px) {
              width: 60%;
              margin: 20px auto;
            }

            @media only screen and (max-width: 1023px) {
              width: 100%;
              height: inherit;
              padding: 20px 16px;
              font-size: 15px;
              line-height: 22px;
              margin: 15px 0;
            }

            p {
              @media only screen and (max-width: 1023px) {
                margin: 10px 0;
              }
            }

            .icons {
              justify-content: space-between;
            }
          }

          .wyylde {
            background-color: #9c0c00;
            background-size: cover;
            color:white;

            .logo {
              width: 245px;
              margin: 20px 0 0;
            }

            .highlight {
              font-family: "Gotham Black", sans-serif;

              span {
                color: #FF4C42;
              }
            }
          }

          .nsf {
            background: url("./assets/images/nsf_background.png");
            background-size: cover;
            position: relative;

            .reflect {
              width: 120%;
              height: 130px;
              position: absolute;
              background: rgb(255 255 255 / 20%);
              top: -20px;
              transform: rotate(-5deg);

              @media only screen and (max-width: 1023px) {
                height: 120px;
              }
            }

            > a {
              z-index: 1;

              .logo-container {
                background: black;
                border-radius: 50%;
                width: 136px;
                height: 136px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;

                .logo {
                  width: 60%;
                }
              }
            }

            .highlight {
              color: white;
              font-family: "Gotham Black", sans-serif;
            }
          }
        }
      }
    }
  }

  &__tools {
    background-color: $yellow;

    .content {
      > .flex {
        justify-content: space-between;

        img {
          width: 73px;
          height: 107px;
          margin-top: 15px;

          @media only screen and (max-width: 1023px) {
            margin-top: -30px;
          }
        }
      }

      &__description-tools {
        margin-top: 20px;
        grid-template-columns: repeat(6, 1fr);
        column-gap: 60px;
        row-gap: 60px;

        @media only screen and (max-width: 1160px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media only screen and (max-width: 1023px) {
          grid-template-columns: repeat(2, 1fr);
          column-gap: 40px;
          row-gap: 40px;
        }

        .tool {
          background: $light;
          position: relative;
          padding: 25px 0;
          min-width: 123px;
          min-height: 112px;
          max-width: 150px;
          max-height: 115px;
          box-sizing: border-box;
          margin: auto;
          width: 100%;
          display: flex;

          p {
            display: none;
            position: absolute;
            text-transform: uppercase;
            font-family: "Gotham Black", sans-serif;
            bottom: -20px;
            margin: 0;
            left: 50%;
            transform: translateX(-50%);
            font-size: 34px;
          }

          img {
            display: block;
            margin: auto;

            &.active {
              display: none;
            }
          }

          &:hover {
            box-shadow: 6px 6px 0 rgb(0 0 0 / 10%);

            img {
              display: none;

              &.active {
                display: block;
              }
            }

            p {
              display: block;
            }
          }
        }
      }
    }
  }


  &__jobs {
    background-color: $light;
    background-position: center top;
    background-size: 40%;

    .content {
      @media only screen and (max-width: 1023px) {
        margin: 50px 0 20px;
      }

      > .flex {
        justify-content: space-between;

        img {
          width: 183px;
          height: 136px;

          @media only screen and (max-width: 1023px) {
            width: 102px;
            height: 76px;
          }
        }
      }

      &__description {
        .offers {
          text-align: center;

          @media only screen and (max-width: 1023px) {
            margin-top: inherit;
            .button {
              display: block;
            }
          }
        }
      }
    }
  }

  &__contact {
    .content {
      &__description {
        > p {
          margin-bottom: 60px;

          @media only screen and (max-width: 1023px) {
            margin-bottom: 40px;
          }
        }

        .flex {
          @media only screen and (max-width: 640px) {
            flex-direction: column;
          }

          > img {
            width: 210px;
            height: 132px;
            margin: 40px 132px;

            @media only screen and (max-width: 940px) {
              margin: 40px 82px;
            }

            @media only screen and (max-width: 1023px) {
              width: 120px;
              height: 75px;
              margin: 40px 100px;
            }

            @media only screen and (max-width: 640px) {
              width: 120px;
              height: 75px;
              margin: 0 auto 60px;
            }
          }

          .contact {
            > div {
              display: flex;
              margin-bottom: 20px;

              .icon {
                width: 50px;
                text-align: center;
              }

              p {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  &__footer {
    background-color: $dark;

    .content {
      margin: 40px auto 20px;
      
      a {
        color:white !important;
        text-decoration: underline;
      }

      #societe {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        font-size: 10px;
        text-align: center;
      }

      .flex {
        justify-content: center;

        @media only screen and (max-width: 1023px) {
          flex-direction: column;
        }

        &.apps {
          a {
            margin: 0 50px;
            width: 145px;
            text-align: center;

            @media only screen and (max-width: 1023px) {
              margin: 10px auto;
            }

            img {
              &.wyylde {
                width: 145px;
              }

              &.kinky {
                width: 110px;
              }

              &.nsf {
                width: 67px;
              }
            }
          }
        }

        &.links {
          margin: 30px 0;
          font-size: 16px;

          @media only screen and (max-width: 1023px) {
            margin: 10px auto;
          }

          a {
            margin: 0 80px;

            @media only screen and (max-width: 1023px) {
              margin: 10px auto;
            }
          }
        }
      }

      p.small {
        font-size: 12px;
        text-align: center;
        margin-top: 80px;
      }
    }
  }
}
